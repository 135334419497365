import { IonHeader } from "@ionic/react";
import "./scss/home-header.scss";
import logo from "../assets/kr4all.svg";
import share from "../assets/share.svg";

interface ContainerProps {}

const HomeHeader: React.FC<ContainerProps> = () => {
  return (
    <IonHeader className="home-header-content ion-no-border">
      <img src={logo} alt="logo" />
      <img src={share} alt="share" />
    </IonHeader>
  );
};

export default HomeHeader;
