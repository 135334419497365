import { IonCard } from "@ionic/react";
import pills from "../assets/pill.png";
import "./scss/upload-pres.scss";

interface ContainerProps {}

const UploadPres: React.FC<ContainerProps> = () => {
  return (
    <IonCard className="upload-pres-card">
      <div className="image-pos">
        <img src={pills} alt="pill" />
        <div className="top-1">
          <div className="heading">Upload Prescription</div>
          <div className="heading-2">
            Get medicines delivered at your doorstep from the nearest pharmacy!
          </div>
        </div>
      </div>

      <div className="foot">Upload Prescription</div>
    </IonCard>
  );
};

export default UploadPres;
