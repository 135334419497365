import { IonCard } from "@ionic/react";

import pills from "../assets/iphone2.png";

import "./scss/kr4all-card.scss";

interface ContainerProps {}

const Kr4all: React.FC<ContainerProps> = () => {
  return (
    <IonCard className="kr-4all-card">
      <div className="kr4-card">
        <img src={pills} alt="pills" />
        <div className="top-1">
          <div className="heading">KR4ALL App</div>
          <div className="heading-2">
            Get medicines delivered at your doorstep from the nearest pharmacy!
          </div>
        </div>
      </div>

      <div className="kr-4-foot">Download App</div>
    </IonCard>
  );
};

export default Kr4all;
