import {
  IonButton,
  IonContent,
  IonFooter,
  IonHeader,
  IonPage,
} from "@ionic/react";
import HowItWorks from "../components/HowItWorksCard";
import Kr4all from "../components/Kr4allCard";
import MoreReviews from "../components/MoreReviewsCard";
import "./scss/how.scss";

const HowItPage: React.FC = () => {
  return (
    <IonPage id="how-it-w">
      <IonHeader className="title-how ion-no-border">
        <div>How it works</div>
      </IonHeader>
      <IonContent>
        <div className="magin">
          <HowItWorks />
          <div className="title-how">Customer Reviews</div>
          <MoreReviews />
          <Kr4all />
        </div>
      </IonContent>
      <IonFooter className="ion-no-border">
        <IonButton className="btn">Pay to Pharmacy</IonButton>
      </IonFooter>
    </IonPage>
  );
};

export default HowItPage;
