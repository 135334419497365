import { gql } from "@apollo/client";

export const Q_GET_PHARMACY_BY_ID = gql`
  query allPharmacies($id: uuid!) {
    pharmacy: provider_branches(
      where: {
        _and: [
          { id: { _eq: $id } }
          { provider: { solution: { _eq: pharmacy } } }
          { active: { _eq: true } }
        ]
      }
    ) {
      id
      name
      photo_url
      active
      desc
      summary
      phone
      email
      provider_locations {
        address_line_1
        address_line_2
        address_line_3
        city
        country
        lat
        lng
      }
      provider {
        name
        id
        is_integrated
        desc
        photo_url
        summary
        tags
        phone
      }
    }
  }
`;
