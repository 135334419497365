import pharmacy from "../assets/pharmacy.svg";
import DirectionButton from "./DirectionButton";
import direction from "../assets/direction.svg";
import call from "../assets/call.svg";
import bookmark from "../assets/bookmark.svg";
import "./scss/home-header.scss";
import { useState } from "react";
import { Rating } from "react-simple-star-rating";
interface ContainerProps {
  data: any;
}

const PharmacyCard: React.FC<ContainerProps> = ({ data }) => {
  const location = data?.provider_locations?.[0];

  const [rating, setRating] = useState(0);

  const handleRating = (rate: number) => {
    setRating(rate);
  };
  return (
    <div className="pharmacy-card">
      <div className="center-content">
        <div className="avatar">
          <img src={data?.photo_url ?? pharmacy} alt="phar" />
        </div>
      </div>
      <div className="base-stars">
        <div>
          <Rating onClick={handleRating} ratingValue={rating} size={12} />
        </div>

        <div className="two-three">(233)</div>
      </div>
      <div className="apollo">{data?.name}</div>
      <div className="hsr">{location?.city}</div>
      <div className="space-btw">
        <DirectionButton title="Directions" image={direction} />
        <DirectionButton title="Call now" image={call} />
        <DirectionButton title="Save" image={bookmark} />
      </div>
    </div>
  );
};

export default PharmacyCard;
