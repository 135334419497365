import { IonCard } from "@ionic/react";
import { useState } from "react";
import { Rating } from "react-simple-star-rating";
import "./scss/more-review.scss";

interface ContainerProps {}

const MoreReviews: React.FC<ContainerProps> = () => {
  const [rating, setRating] = useState(0);

  const handleRating = (rate: number) => {
    setRating(rate);
  };
  return (
    <IonCard className="more-review-card">
      <div className="flex-1">
        <div className="first-part">
          <div className="two-3">
            <Rating onClick={handleRating} ratingValue={rating} size={12} />

            <div className="sub-t">(233)</div>
          </div>
          <div className="view">
            View <span> 200 more </span> reviews
          </div>
        </div>
      </div>
      <div className="second-part">View More Reviews</div>
    </IonCard>
  );
};

export default MoreReviews;
