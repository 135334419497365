import { IonContent, IonFooter, IonModal } from "@ionic/react";

import "./scss/verify-number.scss";

const VerifyNumber: React.FC = () => {
  return (
    <IonModal isOpen={true} id="verify">
      <IonContent>
        <div className="verify-num">
          <div className="con-1">Verify Your Number</div>
          <div className="con-2">Enter your phone number to continue.</div>
          <input className="in" placeholder="Enter Mobile Number" />
        </div>
      </IonContent>
      <IonFooter className="ion-no-border  foot-ver">
        <button className="proceed">Proceed</button>
      </IonFooter>
    </IonModal>
  );
};

export default VerifyNumber;
