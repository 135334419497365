import { IonCard } from "@ionic/react";
import invoice from "../assets/invoice.svg";
import rx from "../assets/rx.svg";
import card from "../assets/card.svg";
import box from "../assets/box.svg";
import "./scss/how-it-works.scss";

interface ContainerProps {}

const HowItWorks: React.FC<ContainerProps> = () => {
  return (
    <IonCard className="how-it">
      <div className="flex-1">
        <img src={rx} alt="rx" />
        <div className="share">Share Prescription with pharmacy</div>
      </div>
      <div className="outer-box">
        <div className="inner-box"></div>
      </div>
      <div className="flex-1">
        <img src={invoice} alt="rx" />
        <div className="share">Pharmacy sends the invoice</div>
      </div>
      <div className="outer-box">
        <div className="inner-box"></div>
      </div>
      <div className="flex-1">
        <img src={card} alt="rx" />
        <div className="share">Make payment on invoice</div>
      </div>
      <div className="outer-box">
        <div className="inner-box"></div>
      </div>
      <div className="flex-1">
        <img src={box} alt="rx" />
        <div className="share">Gets delivered to your address</div>
      </div>
    </IonCard>
  );
};

export default HowItWorks;
