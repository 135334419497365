import { Redirect, Route } from "react-router-dom";
import { IonApp, IonRouterOutlet, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import Home from "./pages/Home";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.scss";
import HowItPage from "./pages/HowItWorks";
import Reviews from "./pages/Reviews";
// import OtpModal from "./components/OtpModal";
// import VerifyNumber from "./components/VerifyNumberModal";
// import Payment from "./pages/Payment";
// import Success from "./pages/Success";

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  split,
} from "@apollo/client";
import { WebSocketLink } from "@apollo/client/link/ws";
import { HttpLink } from "@apollo/client/link/http";
import { getMainDefinition } from "@apollo/client/utilities";
import VerifyNumber from "./components/VerifyNumberModal";

setupIonicReact({
  mode: "ios",
});

export const useApolloClient = (accessToken: string) => {
  let headers: { [key: string]: string | undefined } = {
    "Content-Type": "application/json",
  };
  if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`;
  } else {
    headers["X-Hasura-Role"] = "public";
  }

  return new ApolloClient({
    cache: new InMemoryCache(),
    link: split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === "OperationDefinition" &&
          definition.operation === "subscription"
        );
      },
      new WebSocketLink({
        uri: process.env.REACT_APP_HASURA_URL_WS,
        options: {
          lazy: true,
          reconnect: true,
          connectionParams: async () => {
            return { headers };
          },
        },
      }),
      new HttpLink({
        uri: process.env.REACT_APP_HASURA_URL,
        headers: headers,
      })
    ),
  });
};

const App: React.FC = () => {
  const client = useApolloClient("");

  return (
    <ApolloProvider client={client}>
      <IonApp>
        <IonReactRouter>
          <IonRouterOutlet>
            <Route path="/pharmacy/details" component={Home}></Route>
            <Route exact path="/how">
              <HowItPage />
            </Route>
            <Route exact path="/review">
              <Reviews />
            </Route>
            <Route exact path="/verify">
              <VerifyNumber />
              {/* <OtpModal /> */}
              {/* <Payment /> */}
              {/* <Success /> */}
            </Route>
            <Route exact path="/">
              <Redirect to="/pharmacy/details" />
            </Route>
          </IonRouterOutlet>
        </IonReactRouter>
      </IonApp>
    </ApolloProvider>
  );
};

export default App;
