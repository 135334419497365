import { IonCard } from "@ionic/react";
import avatar from "../assets/avatar.svg";
import { Rating } from "react-simple-star-rating";
import "./scss/customer-review.scss";
import { useState } from "react";
interface ButtonProps {
  title?: string;
  image?: any;
  backgroundWhite: boolean;
}

const CustomerReviewCard: React.FC<ButtonProps> = ({
  title,
  image,
  backgroundWhite,
}) => {
  const [rating, setRating] = useState(0);

  const handleRating = (rate: number) => {
    setRating(rate);
  };
  return (
    <IonCard
      className={
        backgroundWhite ? "white-customer-card" : "customer-review-card"
      }
    >
      <div className="flex-cen">
        <img src={avatar} alt="avatr" />
        <div>
          <div className="lokesh">Lokesh Mundra</div>
          <div>
            <Rating onClick={handleRating} ratingValue={rating} size={15} />
          </div>
        </div>
      </div>
      <div className="paragraph">
        Lorem ipsum dolor sit amet, consectetur adipiscing elitff we rg. Aliquam
        porta commodo in sed.{" "}
      </div>
    </IonCard>
  );
};

export default CustomerReviewCard;
