import {
  IonButton,
  IonContent,
  IonFooter,
  IonPage,
  IonToolbar,
} from "@ionic/react";
import HomeHeader from "../components/HomeHeader";
import HowItWorks from "../components/HowItWorksCard";
import PharmacyCard from "../components/PharmacyCard";
import UploadPres from "../components/UploadPres";
import discount from "../assets/discount.svg";
import "./scss/Home.scss";
import CustomerReviewCard from "../components/CustomerReviewCard";
import Kr4all from "../components/Kr4allCard";
import Slider from "react-slick";
import { useQuery } from "@apollo/client";
import { Q_GET_PHARMACY_BY_ID } from "src/graphql";
import { RouteComponentProps } from "react-router";
import { useQueryParams } from "src/utils";

const Home: React.FC<RouteComponentProps<{}, any, any>> = () => {
  const params = useQueryParams();
  const id = params.get("id");

  const { loading, data } = useQuery(Q_GET_PHARMACY_BY_ID, {
    variables: {
      id,
    },
    skip: !id,
  });
  console.log(data, loading);

  const pharmacy = data?.pharmacy?.[0];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <IonPage id="home-screen">
      <HomeHeader />
      <IonContent>
        <PharmacyCard data={pharmacy} />
        <div className="mar-25">
          <UploadPres />
          <div className="discount-off">
            <img src={discount} alt="discount" />
            <div>
              <div className="title">Discount Offers </div>
              <div className="sub-t">Order online to avail XX discount</div>
            </div>
          </div>
          <div className="how-it-p">How It Works</div>
          <HowItWorks />
          <div className="how-it-p">Customer Reviews</div>

          <div style={{ paddingBottom: "30px" }}>
            <Slider {...settings}>
              <CustomerReviewCard backgroundWhite={false} />
              <CustomerReviewCard backgroundWhite={false} />
              <CustomerReviewCard backgroundWhite={false} />
              <CustomerReviewCard backgroundWhite={false} />
            </Slider>
          </div>
          <Kr4all />
        </div>
      </IonContent>
      <IonFooter className="ion-no-border">
        <IonToolbar>
          <IonButton className="btn">Pay to Pharmacy</IonButton>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default Home;
