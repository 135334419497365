import { IonContent, IonHeader, IonPage } from "@ionic/react";
import back from "../assets/arrow.svg";
import CustomerReviewCard from "../components/CustomerReviewCard";
import "./scss/reviews.scss";

const jsonData = Array.apply(null, Array(5));

const Reviews: React.FC = () => {
  return (
    <IonPage id="review-page">
      <IonHeader className="review-header ion-no-border">
        <img src={back} alt="back" />
        <div className="title-1">Reviews</div>
        <img className="hide" src={back} alt="back" />
      </IonHeader>
      <IonContent>
        {jsonData.map((data) => (
          <div>
            <CustomerReviewCard backgroundWhite={true} />
            <div className="bord"></div>
          </div>
        ))}
      </IonContent>
    </IonPage>
  );
};

export default Reviews;
