import { IonButton } from "@ionic/react";
import "./scss/home-header.scss";
interface ButtonProps {
  title: string;
  image: any;
}

const DirectionButton: React.FC<ButtonProps> = ({ title, image }) => {
  return (
    <IonButton className="direction-button">
      <img src={image} alt="direc" />
      <div className="cont">{title}</div>
    </IonButton>
  );
};

export default DirectionButton;
